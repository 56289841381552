<template>
  <div>
    <div class="row" style="margin-top: 20px !important;">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-dept 
          type="edit" 
          label="피 심사팀" 
          name="deptCd" 
          :editable="false"
          :disabled="true"
          v-model="targetDept.deptCd" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-dept 
          type="edit" 
          label="심사자 부서" 
          name="teamDeptCd" 
          :editable="false"
          :disabled="true"
          v-model="targetDept.teamDeptCd" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-field
          :required="true"
          :disabled="true"
          :editable="editable"
          label="심사자"
          name="teamUserId"
          v-model="targetDept.teamUserId" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <c-select
          :editable="false"
          :disabled="true"
          codeGroupCd="INTERNAL_AUDIT_RESULT_STATUS_CD"
          type="edit"
          itemText="codeName"
          itemValue="code"
          name="internalAuditResultStatusCd"
          label="심사상태"
          v-model="targetDept.internalAuditResultStatusCd"
        ></c-select>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-text
          :editable="editable"
          :disabled="disabled"
          label="비고"
          name="remark"
          v-model="targetDept.remark">
        </c-text>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="내부심사 체크리스트"
          :columns="grid.columns"
          :data="targetDept.list"
          :gridHeight="gridHeight"
          :merge="grid.merge"
          :editable="editable && !disabled"
          :isExcelDown="false"
          :filtering="false"
          :columnSetting="false"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn 
                v-show="editable && !disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="targetDept"
                mappingType="PUT"
                label="저장" 
                icon="save"
                @beforeAction="saveChecklist"
                @btnCallback="saveChecklistCallback" />
              <c-btn 
                v-show="editable && !disabled && targetDept.internalAuditResultStatusCd == 'IARSC00001'" 
                :isSubmit="isSave2"
                :url="saveUrl2"
                :param="targetDept"
                mappingType="POST"
                label="심사완료" 
                icon="check"
                @beforeAction="saveChecklist2"
                @btnCallback="saveChecklistCallback2" />
            </q-btn-group>
          </template>
          <!-- <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && !disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="saiInternalActionChecklistResultId"
                ibmTaskTypeCd="ITT0000150"
                ibmTaskUnderTypeCd="ITTU000155"
                @imprChange="imprChange"
              />
            </template>
          </template> -->
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'internal-action-checklist',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',
        deptCd: '',
        saiInternalActionId: '',
        teamDeptCd: '',
        reviewUserId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      targetDept: {
        saiInternalActionId: '',
        deptCd: '',
        teamDeptCd: '',
        teamDeptName: '',
        teamUserId: '',
        teamUserName: '',
        internalAuditResultStatusCd: '',
        internalAuditResultStatusName: '',
        remark: '',
        list: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalAuditClassName' },
          { index: 1, colName: 'saiInternalAuditClassNameGubun' },
        ],
        columns: [
          {
            name: 'saiInternalAuditClassName',
            field: 'saiInternalAuditClassName',
            label: 'ISO 구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            label: '관련표준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   //순서(순번)
          //   label: 'LBLSORTORDER',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            label: '심사 항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'result',
            field: 'result',
            label: '내부심사결과',
            child: [
              {
                name: 'sar0000001',
                field: 'sar0000001',
                label: '적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000005',
                field: 'sar0000005',
                label: '부적합',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              // {
              //   name: 'sar0000010',
              //   field: 'sar0000010',
              //   //관찰사항
              //   label: 'LBL0010144',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
              // {
              //   name: 'sar0000015',
              //   field: 'sar0000015',
              //   //비적용
              //   label: 'LBL0010145',
              //   align: 'center',
              //   style: 'width:40px',
              //   sortable: false,
              //   type: 'check',
              //   checkIcon: 'task_alt',
              //   uncheckIcon: 'radio_button_unchecked',
              //   'true': 'Y',
              //   'false': 'N',
              //   color: 'green', 
              // },
            ]
          },
          {
            name: 'observation',
            field: 'observation',
            label: '관찰내용',
            align: 'left',
            style: 'width:250px',
            sortable: false,
            type: 'textarea',
          },
          // {
          //   name: 'customCol',
          //   field: 'customCol',
          //   //개선번호/개선진행상태
          //   label: 'LBLIMPRNO_PROGRESS',
          //   align: 'center',
          //   style: 'width:150px',
          //   type: 'custom',
          //   sortable: false
          // },
        ],
        data: [],
        height: '780px',
      },
      selectedDeptCd: '',
      selectedDeptName: '',
      check: false,
      editable: true,
      listUrl: '',
      listDeptUrl: '',
      saveUrl: '',
      saveUrl2: '',
      deleteUrl: '',
      isSave: false,
      isSave2: false, 
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.targetDept.internalAuditResultStatusCd == 'IARSC00002';
    },
    gridHeight() {
      let _margin = 130;
      if (!this.disabled) {
        _margin += 40
      }
      return String(this.contentHeight - _margin) + 'px';
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['systemElementsName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.internal.action.checklistResult.save.url + '/dept';
      this.saveUrl2 = transactionConfig.sai.internal.action.checklistResult.save.url + '/dept';
      this.listDeptUrl = selectConfig.sai.internal.action.checklistResult.depts.url;
      // code setting
      // list setting
      this.getCheckDeptlist();
    },
    getCheckDeptlist() {
      this.$http.url = this.listDeptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalActionTargetDeptId: this.popupParam.saiInternalActionTargetDeptId,
        saiInternalActionId: this.popupParam.saiInternalActionId,
        deptCd: this.popupParam.deptCd,
        teamDeptCd: this.popupParam.teamDeptCd,
        reviewUserId: this.popupParam.reviewUserId,
      }
      this.$http.request((_result) => {
        this.targetDept = _result.data;
        if (!this.targetDept.teamUserId) {
          this.targetDept.teamUserId = this.$store.getters.user.userId;
        }
      },);
    },
    tableDataChange(props, col) {
      // let blackList = ['itemName', 'aimMatter', 'observation']
      let whiteList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
      if (whiteList.indexOf(col.name) > -1 && props.row[col.name] === 'Y') {
        this.$_.forEach(whiteList, item => {
          if (item === col.name) return
          this.$set(props.row, item, 'N')
        })
      }
    },
    saveChecklist() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?', 
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.targetDept.regUserId = this.$store.getters.user.userId;
          this.targetDept.chgUserId = this.$store.getters.user.userId;
          this.targetDept.internalAuditResultStatusCd = 'IARSC00001';
          let resultList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
          this.$_.forEach(this.targetDept.list, check => {
            check.deptCd = this.targetDept.teamDeptCd;
            this.$_.forEach(resultList, item => {
              if (check[item] === 'Y') {
                check.saiInternalAuditResultCd = this.$_.toUpper(item);
                return false;
              }
            })
          })
          this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveChecklist2() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '심사완료를 하시면 더이상 수정이 되지 않습니다. 심사완료 하시겠습니까?',    
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.targetDept.chgUserId = this.$store.getters.user.userId;
          this.targetDept.internalAuditResultStatusCd = 'IARSC00002';
          let resultList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
          this.$_.forEach(this.targetDept.list, check => {
            check.deptCd = this.targetDept.teamDeptCd;
            this.$_.forEach(resultList, item => {
              if (check[item] === 'Y') {
                check.saiInternalAuditResultCd = this.$_.toUpper(item);
                return false;
              }
            })
          })
          this.isSave2 = !this.isSave2
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveChecklistCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getCheckDeptlist();
    },
    saveChecklistCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getCheckDeptlist();
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>